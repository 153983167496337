<template>
  <div class="bg">
    <div class="head">
      <v-row class="ma-auto head-info" justify="space-between">
        <v-col cols="auto" class="my-auto col" v-if="showIcon">
          <v-img
            src="@/assets/img_hy_icon_text.png"
            max-height="30px"
            max-width="154px"
          ></v-img>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="my-auto col pa-0">
          <pc-frame :isShowMenu="false" class="user-info my-auto"> </pc-frame>
        </v-col>
      </v-row>
    </div>
    <div class="content-bg">
      <div class="content">
        <div class="form">
          <p class="mx-auto mb-8 text-center content-title">请设置密码</p>
          <p class="pa-0 ma-0 common--text">弘衍账号</p>
          <v-text-field
            class="mt-4 f14"
            outlined
            disabled
            filled
            v-model="account"
          >
          </v-text-field>
          <p class="pa-0 mt-2 common--text">发送验证码</p>
          <pc-sms-text-field
            @smsInputed="getSms"
            :phone="account"
            :smsError="smsError"
          ></pc-sms-text-field>
          <p class="pa-0 mt-2 common--text">新密码</p>
          <v-text-field
            class="f14"
            type="password"
            outlined
            color="primary"
            v-model="firstPwd"
            :error-messages="firstPwdError"
            placeholder="登录密码6～16位数字或字母"
          ></v-text-field>
          <p class="pa-0 mt-2 common--text">确认密码</p>
          <v-text-field
            class="f14"
            type="password"
            outlined
            color="primary"
            v-model="secondPwd"
            :error-messages="secondPwdError"
            placeholder="再次确认新密码"
          ></v-text-field>
          <v-btn
            class="primary mx-auto white--text f14 mt-6"
            width="100%"
            max-width="288px"
            outlined
            rounded
            @click="comfirmClickHandler"
            >确认</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PcMixins from "@/views/pc/_mixins/pc-mixins.js";
import PcFrame from "@/views/pc/index/pc-frame.vue";
import UserDataMixins from "@/views/pc/index/_mixins/pc-user-mixins.js";
import { sendCode } from "@/api/login";
import { getUuid } from "@/views/common/web/uuid";
import PcSmsTextField from "./components/pc-sms-text-field.vue";
import { modifyPassword } from "@/api/api-pc";
import { isEmpty } from "@/views/common/web/isEmpty";
export default {
  mixins: [UserDataMixins],
  data() {
    return {
      sms: "",
      smsError: "",
      firstPwd: "",
      firstPwdError: "",
      secondPwd: "",
      secondPwdError: "",
    };
  },
  methods: {
    getSms(sms) {
      this.sms = sms;
    },
    comfirmClickHandler() {
      this.verifySms();
      this.verifyFirstPassword();
      this.verifySecondPassword();
      this.changePassword();
    },
    verifyFirstPassword() {
      if (isEmpty(this.firstPwd)) {
        this.firstPwdError = "请输入密码";
        return;
      }

      const pattern = /^[0-9a-zA-Z]{6,16}$/i;
      if (!pattern.test(this.firstPwd)) {
        this.firstPwdError = "登录密码为6～16位数字或字母";
        return;
      }
    },
    verifySecondPassword() {
      if (isEmpty(this.secondPwd)) {
        this.secondPwdError = "请再次输入密码";
        return;
      }

      if (this.firstPwd !== this.secondPwd) {
        this.secondPwdError = "密码不一致";
        return;
      }
    },
    verifySms() {
      if (isEmpty(this.sms)) {
        this.smsError = "请输入验证码";
        return;
      }
      const pattern = /^[0-9]*$/;
      if (!pattern.test(this.sms)) {
        this.smsError = "验证码错误";
        return;
      }
    },
    changePassword() {
      modifyPassword(this.firstPwd, this.secondPwd, this.sms)
        .then(() => {
          this.showSnackbar({ msg: "密码修改成功" });
        })
        .catch(({ res, statusCode }) => {
          if (!res || !res.message) {
            return;
          }
          this.showSnackbar({ msg: res.message });
        });
    },
  },
  computed: {
    showIcon() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  watch: {
    sms(val) {
      this.smsError = "";
    },
    firstPwd(val) {
      this.firstPwdError = "";
    },
    secondPwd(val) {
      this.secondPwdError = "";
    },
  },
  components: {
    PcFrame,
    PcSmsTextField,
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.head {
  max-height: 78px;
  height: 78px;
}

.head-info {
  max-width: 1200px;
  max-height: 78px;
}

.col {
  max-height: 78px;
}

.user-info {
  height: 100%;
}

.content-bg {
  padding: 42px;
  width: 100%;
  height: calc(100% - 70px);
  background: #f8f8f8;
}

.content {
  padding: 40px;
  max-width: 1200px;
  height: 100%;
  margin: auto;
  background-color: white;
  overflow: auto;
}

.form {
  max-width: 285px;
  margin: auto;
}

.content-title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 30px;
  letter-spacing: 1px;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot {
  min-height: 40px;
}

::v-deep .v-text-field--outlined fieldset {
  border-color: #e8e8e8;
}

::v-deep .v-input__append-outer {
  margin-top: 0px;
}
</style>
