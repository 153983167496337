<template>
  <v-text-field
    class="f14"
    v-bind="$attrs"
    v-model="smsText"
    placeholder="4位验证码"
    type="tel"
    maxlength="4"
    outlined
    :rules="[rules.sms]"
    :error-messages="smsError"
  >
    <v-btn
      slot="append-outer"
      class="f14"
      color="primary"
      outlined
      rounded
      :disabled="isSendSms"
      @click="onSmsClick"
      >{{ btnText }}</v-btn
    >
  </v-text-field>
</template>

<script>
import { isEmpty } from "@/views/common/web/isEmpty";
import { getUuid } from "@/views/common/web/uuid";
import { sendCode } from "@/api/login";
export default {
  inheritAttrs: false,
  name: "pc-sms-text-field",
  props: {
    phone: {
      type: String,
      default: "",
    }, //手机号
    smsError: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      smsText: "",
      isSendSms: false, //是否已经发送了验证码
      btnText: "获取验证码", //发送验证码按钮的文本
      countDownTime: 60, //倒计时时间
      internalId: -1,
      rules: {
        sms: (v) => {
          if (isEmpty(v)) {
            return true;
          }
          if (v.length > 4) {
            v = v.slice(0, 4);
          }
          const pattern = /^[0-9]*$/;
          if (pattern.test(v)) {
            // this.$emit("onInput", v, true);
            return true;
          } else {
            // this.$emit("onInput", v, false);
            return "验证码错误";
          }
        },
      },
    };
  },
  methods: {
    onSmsClick() {
      if (this.isSendSms) return;

      this.isSendSms = true;
      sendCode(this.phone, getUuid())
        .then((res) => {
          console.log(">>>>>发送短信成功");
          this.internalId = setInterval(() => {
            this.countDownTime--;
            if (this.countDownTime > 0) {
              this.btnText = this.countDownTime + "s后重发";
            } else {
              this.isSendSms = false;
              clearInterval(this.internalId);
              this.btnText = "发送验证码";
              this.countDownTime = 60;
            }
          }, 1000);
          this.showSnackbar({ msg: "验证码已发送" });
        })
        .catch(({ code, msg }) => {
          this.isSendSms = false;
          clearInterval(this.internalId);
          this.btnText = "发送验证码";
          this.countDownTime = 60;
          if (!msg) {
            return;
          }

          this.showSnackbar({ msg });
        });
    },
  },
  watch: {
    smsText(val) {
      this.$emit("smsInputed", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
